// TODO: Remove all variables that in the future can be replaced by either designsystem or tailwind

$black: #212121;
$white: white;
$grey: #504f4f;
$grey-light: #d8dada;
$grey-lighter: #e9e9e9;
$blue-dark: #003057;
$red-dark: #8b0a0a;
$red: #e03c31;
$red-light: #f3bebe;
$yellow-dark: #d39000;
$yellow: #ed8b00;
$yellow-light: #fff4e6;
$orange: #f8a83d;
$blue: #71c5e8;
$blue-light: #82b3e2;
$blue-lighter: #e6f3ff;
$blue-lighter-2: #e6f2f6;
$blue-lightest: #edf8fe;
$green-dark: #366558;
$green: #95dcca;
$green-light: #d4f1d4;
$purple-dark: #a83d72;
$purple: #865e9c;
$purple-lightest: #e7e3f1;

// Unique helfo variables used for if-checks
$helfo-hover-light: $blue-lightest;


// Colors only meant for warnings
$green-warning: #007a00;
$yellow-warning: #f0c617;
$red-warning: #ad0017;
$secondary-color: $blue-dark;
$primary-color: $blue-dark;
$primary-color-light: $blue-dark;
$primary-color-lighter: $blue-lighter;
$primary-color-lightest: $blue-lightest;
$link-color: $blue-dark;

// List of colors
$colors: (
  black: $black,
  grey: $grey,
  white: white,
  grey-light: $grey-light,
  grey-lighter: $grey-lighter,
  blue-dark: $blue-dark,
  blue: $blue,
  blue-light: $blue-light,
  blue-lighter: $blue-lighter,
  yellow-dark: $yellow-dark,
  yellow: $yellow,
  yellow-light: $yellow-light,
  red-dark: $red-dark,
  red: $red,
  red-light: $red-light,
  orange: $orange,
  green-dark: $green-dark,
  green: $green,
  green-light: $green-light,
  purple: $purple,
  purple-dark: $purple-dark,
  green-warning: $green-warning,
  yellow-warning: $yellow-warning,
  red-warning: $red-warning
);

// OLD Font sizes
$extra-large-text: 2.5rem;
$large-text: 1.8rem;
$medium-large-text: 1.2rem;
$meta-text: 0.8725rem;

// Logo
$logo: '../static/helfo_logo.svg';
$logo-small: '../static/helfo_logo.svg';
$logo-qa: '../static/helfo_logo.svg';

// Custom icons
$arrow-down: '../static/icons/arrow_down_helfo.svg';
$arrow-left: '../static/icons/arrow_left_helfo.svg';
$arrow-right: '../static/icons/arrow_right_helfo.svg';
$chevron: '../static/icons/chevron_helfo.svg';

@import './variables-common';
